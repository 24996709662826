
import { Component, Vue } from 'vue-property-decorator'

import { Pageable } from '@/core/general'

import { EventCard } from '../components/organisms/EventCard'
import { EventModel } from '../models'
import { EventsRepositoryType, IEventsRepository } from '../contracts'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<EventSearchResultsPage>({
  name: 'EventSearchResultsPage',
  components: {
    EventCard
  },
  mounted (): void {
    this.fetchEventsOfQuery()
  }
})
export class EventSearchResultsPage extends Vue {
  private readonly eventsRepository =
    this.$container.get<IEventsRepository>(EventsRepositoryType)

  public events: Pageable<EventModel> | null = null

  protected get searchPhrase (): string {
    return (this.$route.query.q && typeof this.$route.query.q === 'string' &&
      this.$route.query.q.length) ? this.$route.query.q : ''
  }

  protected async fetchEventsOfQuery (): Promise<void> {
    try {
      this.events = await this.eventsRepository.search(this.searchPhrase, 1000)
    } catch (e) {
      console.log(e)
    }
  }
}
export default EventSearchResultsPage
